html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
}

.App {
  background-color: #F8F9FB;
  margin-top: 64px;
}
